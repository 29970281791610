
import ('../css/style.sass');
import ('./bootstrap.bundle.min.js');

// let Name = document.getElementById('ContactUs-Name');
// let Email = document.getElementById('ContactUs-Email');
// let Msg = document.getElementById('ContactUs-Msg');
// function
// const isEmpty = (val) => {
//   if ( val === '' || val === null || val === undefined) return true;
//   val = String(val);
//   return ( val.replace(/^\s+|\s+$/g,'') == '' )
// };
// const verifyMail = (email) => {
//   const emailRule = /^[_a-z0-9-]+(\.[+_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,6})$/i;
//   return emailRule.test(email);
// };
// const verifyForm = () => {
//   let pass = true;
//   if(isEmpty(Name.value)){
//     Name.classList.add('border-danger')
//     pass = false;
//   }
//   if(isEmpty(Email.value) || !verifyMail(Email.value)){
//     Email.classList.add('border-danger')
//     pass = false;
//   }
//   if(isEmpty(Msg.value)){
//     Msg.classList.add('border-danger')
//     pass = false;
//   }
//   console.log('verify '+ pass)
//   return pass
// }
// const clearFormError = () => {
//   Name.classList.remove('border-danger')
//   Email.classList.remove('border-danger')
//   Msg.classList.remove('border-danger')
// }

// event listener
window.addEventListener('load', () => {
  console.log('load')
  setTimeout(()=>{
    document.querySelector('.preload-bg').classList.add('ra-hidden')
    setTimeout(()=>{
      document.querySelector('.preload-bg').parentNode.removeChild(document.querySelector('.preload-bg'));
    }, 500)
    document.querySelector('body').classList.remove('ra-loaded');
    document.getElementById('ra-year').innerHTML = new Date().getFullYear();
    setTimeout(()=>{
      document.querySelector('body').classList.remove('ra-preload');
    }, 1500)
  }, 800)
})
window.addEventListener('scroll', () => {
  // console.log('scroll')
  let scrollTop = window.pageYOffset;
  let navElement = document.querySelector('nav');
  let isBgColor = navElement.classList.contains('ra-nav-bg')
  if(scrollTop > 10 && !isBgColor){
    navElement.classList.add('ra-nav-bg');
  }else if(scrollTop == 0 && isBgColor){
    navElement.classList.remove('ra-nav-bg');
  }
})
document.getElementById('navBtn').addEventListener('click', (e) => {
  // console.log('click #navbarNav ul')
  const menu = document.getElementById('navMenu');
  if(menu.classList.contains('show')){
    menu.classList.remove('show')
  }else{
    menu.classList.add('show')
  }
})
document.querySelector('#navMenu ul').addEventListener('click', (e) => {
  // console.log('click #navbarNav ul')
  const menu = document.getElementById('navMenu');
  if(window.innerWidth <= 767.98 && menu.classList.contains('show')){
    document.getElementById('navMenu').classList.remove('show')
  }
})
// document.getElementById('ContactUs-Submit').addEventListener('click', async (e) => {
//   console.log('click ContactUs-Submit')
//   if(!verifyForm()) return
// })
// watch
// Name.addEventListener('keypress', () => {
//   clearFormError()
// })
// Email.addEventListener('keypress', () => {
//   clearFormError()
// })
// Msg.addEventListener('keypress', () => {
//   clearFormError()
// })



